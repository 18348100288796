import { makeStyles } from "@mui/styles";
import Footer from "components/Footer/Footer.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styles from '../styles/staticPages.js';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);


export default function PrivacyPolicy(props) {
    const { t,i18n } = useTranslation();
    const isRTL = i18n.dir();
  const classes = useStyles();
  const settings = useSelector(state => state.settingsdata.settings);
  const { ...rest } = props;
  return (
    <div style={{margin:'-8px'}}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/header-back.jpg").default} />
      
      <div style={{ fontFamily: 'Arial, sans-serif', margin: '40px', lineHeight: '1.6', color: '#333' }}>
        <h1 style={{ color: 'red', fontSize: '24px' }}>ONLINE PRIVACY POLICY AGREEMENT</h1>
        <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>December 16, 2024</h2>
        <p>
          Taxi2fly values its users' privacy. This Privacy Policy ("Policy") will help you understand how we collect and use personal information from those who visit our website or make use of our online facilities and services, and what we will and will not do with the information we collect. Our Policy has been designed and created to ensure those affiliated with Taxi2fly of our commitment and realization of our obligation not only to meet, but to exceed, most existing privacy standards.
        </p>
        <p>
          We reserve the right to make changes to this Policy at any given time. If you want to make sure that you are up to date with the latest changes, we advise you to frequently visit this page. If at any point in time Taxi2fly decides to make use of any personally identifiable information on file, in a manner vastly different from that which was stated when this information was initially collected, the user or users shall be promptly notified by email. Users at that time shall have the option as to whether to permit the use of their information in this separate manner.
        </p>
        <p>
          This Policy applies to Taxi2fly, and it governs any and all data collection and usage by us. Through the use of taxi2fly.net, you are therefore consenting to the data collection procedures expressed in this Policy.

        </p>
        <p>Please note that this Policy does not govern the collection and use of information by companies that
Taxi2fly does not control, nor by individuals not employed or managed by us. If you visit a website that
we mention or link to, be sure to review its privacy policy before providing the site with information. It is
highly recommended and suggested that you review the privacy policies and statements of any website
you choose to use or frequent to better understand the way in which websites garner, make use of and
share the information collected.
</p>
<h3 style={{ color: 'red', fontWeight: 'bold' }}>Specifically, this Policy will inform you of the following</h3>
<ul style={{ listStyleType:'none' }}>
    <li>1. What personally identifiable information is collected from you through our website;</li>
    <li>2. Why we collect personally identifiable information and the legal basis for such collection;</li>
    <li>3. How we use the collected information and with whom it may be shared;
        <li>4. What choices are available to you regarding the use of your data; and</li>
        <li>5. The security procedures in place to protect the misuse of your information.</li>
    </li>
</ul>
        <h3 style={{ color: 'red', fontWeight: 'bold' }}>Information We Collect</h3>
        <p>
          It is always up to you whether to disclose personally identifiable information to us, although if you elect not to do so, we reserve the right not to register you as a user or provide you with any products or services. This website collects various types of information, such as:
        </p>
        <ul>
          <li>Voluntarily provided information which may include your name, address, email address, billing and/or credit card information, etc., which may be used when you purchase products and/or services and to deliver the services you have requested.</li>
          <li>
            Information automatically collected when visiting our website, which may include cookies, third-party tracking technologies, and server logs.
          </li>
        </ul>
        <p>
          In addition, Taxi2fly may have the occasion to collect non-personal anonymous demographic information, such as age, gender, household income, political affiliation, race, and religion, as well as the type of browser you are using, IP address, or type of operating system, which will assist us in providing and maintaining superior quality service.
        </p>
        <p>
          Taxi2fly may also deem it necessary, from time to time, to follow websites that our users may frequent to glean what types of services and products may be the most popular to customers or the general public.
        </p>
        <p>
          Please rest assured that this site will only collect personal information that you knowingly and willingly provide to us by way of surveys, completed membership forms, and emails. It is the intent of this site to use personal information only for the purpose for which it was requested, and any additional uses specifically provided for on this Policy.
        </p>
        <h3 style={{ color: 'red', fontWeight: 'bold' }}>Location Data Collection</h3>
        <p>
        Taxi2fly may collect location data when you use our app. This information is used to provide location-based services, such as [e.g., finding nearby taxis, displaying user location on the map, etc.]. We do not share your location data with third parties, except as necessary to provide these services.
        </p>
        <p>
        You can control the collection of location data by adjusting the settings in your device. If you prefer not to share your location, you can opt-out by disabling location services in the app or in your device’s settings.
        </p>
        <h3 style={{ color: 'red', fontWeight: 'bold' }}>Why We Collect Information and For How Long</h3>
        <h4 style={{ fontWeight: 'bold' }}>We are collecting your data for several reasons:</h4>
        <ul>
          <li>To better understand your needs and provide you with the services you have requested;</li>
          <li>To fulfill our legitimate interest in improving our services and products;</li>
          <li>To send you promotional emails containing information we think you may like when we have your consent to do so;</li>
          <li>To contact you to fill out surveys or participate in other types of market research, when we have your consent to do so;</li>
          <li>To customize our website according to your online behavior and personal preferences.</li>
        </ul>
        <p>
          The data we collect from you will be stored for no longer than necessary. The length of time we retain said information will be determined based upon the following criteria: the length of time your personal information remains relevant; the length of time it is reasonable to keep records to demonstrate that we have fulfilled our duties and obligations; any limitation periods within which claims might be made; any retention periods prescribed by law or recommended by regulators, professional bodies, or associations; the existence of your consent; and our legitimate interest in keeping such information as stated in this Policy.
        </p>
        <h3 style={{ color: 'red', fontWeight: 'bold' }}>Use of Information Collected</h3>
        <p>Taxi2fly does not now, nor will it in the future, sell, rent or lease any of its customer lists and/or names to
        any third parties</p>
        <p>Taxi2fly may collect and may make use of personal information to assist in the operation of our website
and to ensure delivery of the services you need and request. At times, we may find it necessary to use
personally identifiable information as a means to keep you informed of other possible products and/or
services that may be available to you from taxi2fly.net</p>
<p>Taxi2fly may also be in contact with you with regards to completing surveys and/or research
questionnaires related to your opinion of current or potential future services that may be offered.</p>

<p>Taxi2fly uses various third-party social media features including but not limited to Facebook, Instagram,
Twitter and other interactive programs. These may collect your IP address and require cookies to work
properly. These services are governed by the privacy policies of the providers and are not within
Taxi2fly's control.
</p>

<h3 style={{ color: 'red', fontWeight: 'bold' }}>Disclosure of Information</h3>
<p>Taxi2fly may not use or disclose the information provided by you except under the following
circumstances: · as necessary to provide services or products you have ordered; · in other ways described
in this Policy or to which you have otherwise consented; · in the aggregate with other information in
such a way so that your identity cannot reasonably be determined; · as required by law, or in response to
a subpoena or search warrant; · to outside auditors who have agreed to keep the information
confidential; · as necessary to enforce the Terms of Service; · as necessary to maintain, safeguard and
preserve all the rights and property of Taxi2fly</p>

<h3 style={{ color: 'red', fontWeight: 'bold' }}>Non-Marketing Purposes</h3>
<p>Taxi2fly greatly respects your privacy. We do maintain and reserve the right to contact you if needed for
non-marketing purposes (such as bug alerts, security breaches, account issues, and/or changes in
Taxi2fly products and services). In certain circumstances, we may use our website, newspapers, or other
public means to post a notice.
</p>
<h3 style={{ color: 'red', fontWeight: 'bold' }}>Children under the age of 13</h3>
    <p>Taxi2fly's website is not directed to, and does not knowingly collect personal identifiable information
from, children under the age of thirteen (13). If it is determined that such information has been
inadvertently collected on anyone under the age of thirteen (13), we shall immediately take the
necessary steps to ensure that such information is deleted from our system's database, or in the
alternative, that verifiable parental consent is obtained for the use and storage of such information.
Anyone under the age of thirteen (13) must seek and obtain parent or guardian permission to use this
website</p>


<h3 style={{ color: 'red', fontWeight: 'bold' }}>Unsubscribe or Opt-Out</h3>
<p>All users and visitors to our website have the option to discontinue receiving communications from us
by way of email or newsletters. To discontinue or unsubscribe from our website please send an email
that you wish to unsubscribe to . If you wish to unsubscribe or opt-out from any third-party websites,
you must go to that specific website to unsubscribe or opt-out. Taxi2fly will continue to adhere to this
Policy with respect to any personal information previously collected.
</p>
<p>Links to Other Websites</p>

<p>Our website does contain links to affiliate and other websites. Taxi2fly does not claim nor accept
responsibility for any privacy policies, practices and/or procedures of other such websites. Therefore, we
encourage all users and visitors to be aware when they leave our website and to read the privacy
statements of every website that collects personally identifiable information. This Privacy Policy
Agreement applies only and solely to the information collected by our website
</p>
<h3 style={{ color: 'red', fontWeight: 'bold' }}>LOG DATA</h3>
<p>We want to inform you that whenever you use our Service, in a case of an error in the app we collect
data and information (through third party products) on your phone called Log Data. This Log Data may
include information such as your device Internet Protocol (“IP”) address, device name, operating system
version, the configuration of the app when utilizing our Service, the time and date of your use of the
Service, and other statistics.</p>

<h3 style={{ color: 'red', fontWeight: 'bold' }}>COOKIES</h3>
<p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers.
These are sent to your browser from the websites that you visit and are stored on your device's internal
memory.</p>

<p>This Service does not use these “cookies” explicitly. However, the app may use third party code and
libraries that use “cookies” to collect information and improve their services. You have the option to
either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose
to refuse our cookies, you may not be able to use some portions of this Service.</p>

<h3 style={{ color: 'red', fontWeight: 'bold' }}>SERVICE PROVIDERS</h3>
<h4 style={{ fontWeight: 'bold' }}>We may employ third-party companies and individuals due to the following reasons:</h4>
      
      <ul>
        <li>To facilitate our Service;</li>
        <li>To provide the Service on our behalf;</li>
        <li>To perform Service-related services; or</li>
        <li>To assist us in analyzing how our Service is used.</li>
      </ul>
      <p>We want to inform users of this Service that these third parties have access to your Personal
Information. The reason is to perform the tasks assigned to them on our behalf. However, they are
obligated not to disclose or use the information for any other purpose.
</p>
      
<h3 style={{ color: 'red', fontWeight: 'bold' }}>Security</h3>

<p>Taxi2fly takes precautions to protect your information. When you submit sensitive information via the
website, your information is protected both online and offline. Wherever we collect sensitive
information (e.g. credit card information), that information is encrypted and transmitted to us in a
secure way. You can verify this by looking for a lock icon in the address bar and looking for "https" at the
beginning of the address of the webpage.</p>
<p>While we use encryption to protect sensitive information transmitted online, we also protect your
information offline. Only employees who need the information to perform a specific job (for example,
billing or customer service) are granted access to personally identifiable information. The computers and
servers in which we store personally identifiable information are kept in a secure environment. This is all
done to prevent any loss, misuse, unauthorized access, disclosure or modification of the user's personal
information under our control.
</p>
<h3 style={{ color: 'red', fontWeight: 'bold' }}>DELETE ACCOUNT</h3>

<p>User can remove all their data from the system by deleting the account from profile page in mobile app.
</p>
<p>Once you delete your account:</p>

<ul>
    <li>All your data will be purged from the system. Your profile image, email, phone number, social
logins including Google login and all booking history, everything will be permanently removed.
</li>
<li>Deleted user data and account are irrecoverable.</li>
</ul>

<h3 style={{ color: 'red', fontWeight: 'bold' }}>CALIFORNIA PRIVACY RIGHTS</h3>
<p>California law permits residents of California to request certain details about how their information is
shared with third parties for direct marketing purposes. Taxi2fly does not share your personally
identifiable information with third parties for the third parties direct marketing purposes unless you
provide us with consent to do so</p>

<h3 style={{ color: 'red', fontWeight: 'bold' }}>Our Privacy and Policy</h3>
<h4 style={{ color: 'red', fontWeight: 'bold' }}>We like to keep things simple, so here is how we will protect your data:</h4>
      
<ul style={{ listStyleType:'none' }}>

    <li>1.We only collect the data we need.
    </li>
    <li>2.Your data is protected.</li>
    <li>3.We will not share your data with anyone.</li>
    <li>4.If we use your data, it will be to improve your experience with us.</li>
    <li>5.We make it easy for you to tell us when and how you want to hear from us.</li>
</ul>

<h3 style={{ color: 'red', fontWeight: 'bold' }}>Acceptance of Terms</h3>
<p>By using this website, you are hereby accepting the terms and conditions stipulated within the Privacy
Policy Agreement. If you are not in agreement with our terms and conditions, then you should refrain
from further use of our sites. In addition, your continued use of our website following the posting of any
updates or changes to our terms and conditions shall mean that you agree and acceptance of such
changes.</p>

<h3 style={{ color: 'red', fontWeight: 'bold' }}>How to Contact Us</h3>
<p>If you have any questions or concerns regarding the Privacy Policy Agreement related to our website,
please feel free to contact us at the following email, telephone number or mailing address</p>

<p>Email: contact@taxi2fly.net</p>
<p>Telephone Number: +1630-943-0600</p>
      </div>
      
      <Footer/>
    </div>
  );
}
